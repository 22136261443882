import { Flex, FlexProps } from "@chakra-ui/react"
import { RightVArrow } from "components/icons/icons"
import { VFC } from "react"

/**
 * 外部リンクにつけるアイコン 円の中心に右上向きの矢印
 */
export const ExternalLinkIcon: VFC<FlexProps> = (props) => {
  return (
    <Flex
      bgColor="point-black"
      w={{ base: "20px", lg: "30px" }}
      h={{ base: "20px", lg: "30px" }}
      borderRadius={{ base: "10px", lg: "15px" }}
      {...props}
    >
      <RightVArrow
        transform="rotate(-45deg)"
        w={{ base: "6px", lg: "10px" }}
        h={{ base: "6px", lg: "10px" }}
        mx="auto"
        my="auto"
      />
    </Flex>
  )
}
