import {
  Box,
  Button,
  ButtonProps,
  chakra,
  Collapse,
  Container,
  Divider,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Spacer,
  useBreakpointValue,
  useDisclosure,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react"
import { CORPORATE_BASE_URL } from "components/constants"
import { NextImage } from "components/system/NextImage"
import Image from "next/image"
import NextLink, { LinkProps } from "next/link"
import { useEffect, useRef, useState, VFC } from "react"
import { scroller } from "react-scroll"

const RecruitHeader: VFC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, "lg")
  const { isOpen, onToggle, onClose } = useDisclosure()
  const headerRef = useRef()
  useOutsideClick({
    ref: headerRef,
    handler: () => onClose(),
  })
  const Logo = () => {
    return (
      <Box mr={3} display={"flex"} lineHeight={0}>
        <NextLink href={CORPORATE_BASE_URL} passHref>
          <Link>
            <Image
              src="/images/monicle-recruit-logo.svg"
              unoptimized
              alt="monicle log"
              width={207}
              height={33}
            />
          </Link>
        </NextLink>
      </Box>
    )
  }
  const EngineerLpLink: VFC<ButtonProps & { arrow?: boolean }> = (props) => {
    const { arrow, ...others } = props
    return (
      <Button
        as="a"
        href="/engineer/"
        height="46px"
        width="212px"
        mr={4}
        px={3}
        alignItems="center"
        border="thin solid"
        borderColor="#303030"
        borderRadius="0px"
        color="inherit"
        transition="ease .2s"
        position="relative"
        overflow="hidden"
        zIndex={1}
        background="linear-gradient(to left, transparent 50%, #fff 50%)"
        backgroundSize="200% 50%"
        backgroundPosition="right bottom"
        _hover={{
          backgroundPosition: "left bottom",
          color: "black",
        }}
        {...others}
      >
        <Flex flexGrow={1} justifyContent="center">
          <chakra.p
            fontStyle="normal"
            fontWeight="normal"
            fontSize="14px"
            lineHeight="21px"
            letterSpacing="0.055em"
          >
            エンジニア採用はこちら
          </chakra.p>
        </Flex>
        {arrow && (
          <chakra.svg
            w="6px"
            h="12px"
            viewBox="0 0 6.38 12.41"
            fill="none"
            stroke="currentcolor"
            stroke-linecap="square"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m.71,11.71l5-5.5L.71.71" />
          </chakra.svg>
        )}
      </Button>
    )
  }
  const Entry: VFC<ButtonProps & { arrow?: boolean }> = (props) => {
    const { arrow, ...others } = props
    const onClick = () => {
      scroller.scrollTo("jobs", {
        smooth: true,
      })
    }
    return (
      <Button
        as="a"
        href="/#jobs"
        height="46px"
        width="136px"
        mr={"47px"}
        px={3}
        alignItems="center"
        border="thin solid"
        borderColor="#303030"
        borderRadius="0px"
        color="inherit"
        transition="ease .2s"
        position="relative"
        overflow="hidden"
        zIndex={1}
        background="linear-gradient(to left, transparent 50%, #fff 50%)"
        backgroundSize="200% 50%" // 50%なのは隙間対策
        backgroundPosition="right bottom"
        _hover={{
          backgroundPosition: "left bottom",
          color: "black",
        }}
        onClick={onClick}
        {...others}
      >
        <Flex flexGrow={1} justifyContent="center">
          <chakra.p
            fontFamily="serif"
            fontStyle="normal"
            fontWeight="normal"
            fontSize="20px"
            lineHeight="21px"
            letterSpacing="0.055em"
          >
            Entry
          </chakra.p>
        </Flex>
        {arrow && (
          <chakra.svg
            w="13px"
            h="7px"
            viewBox="0 0 13 7"
            fill="currentcolor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.5 6L6.16366 6.36997L6.5 6.67573L6.83634 6.36997L6.5 6ZM1.33634 0.63003L0.966367 0.293693L0.293694 1.03363L0.663664 1.36997L1.33634 0.63003ZM12.3363 1.36997L12.7063 1.03363L12.0336 0.293694L11.6637 0.630031L12.3363 1.36997ZM6.83634 5.63003L1.33634 0.63003L0.663664 1.36997L6.16366 6.36997L6.83634 5.63003ZM11.6637 0.630031L6.16366 5.63003L6.83634 6.36997L12.3363 1.36997L11.6637 0.630031Z" />
          </chakra.svg>
        )}
      </Button>
    )
  }
  const Menu = () => {
    return (
      <Link onClick={onToggle}>
        {isOpen ? (
          <chakra.svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.646569"
              y1="22.6694"
              x2="22.6693"
              y2="0.646681"
              stroke="#303030"
            />
            <line
              x1="1.35355"
              y1="0.646447"
              x2="23.3763"
              y2="22.6692"
              stroke="#303030"
            />
          </chakra.svg>
        ) : (
          <chakra.svg
            width="24px"
            height="23px"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="0.5" x2="24" y2="0.5" stroke="#303030" />
            <line y1="11.5" x2="24" y2="11.5" stroke="#303030" />
            <line y1="22.5" x2="24" y2="22.5" stroke="#303030" />
          </chakra.svg>
        )}
      </Link>
    )
  }
  const Links = () => {
    return (
      <>
        {HeaderLinks.map((headerLink, index) => (
          <Link
            key={headerLink.label}
            href={`/#${headerLink.to}`}
            onClick={() => {
              onClose()
              scroller.scrollTo(headerLink.to, {
                smooth: true,
              })
            }}
          >
            <HStack textStyle="en-title/bold/28pt" spacing={2}>
              <chakra.p>{(index + 1).toString().padStart(2, "0")}</chakra.p>
              <Box w="90px">
                <Divider />
              </Box>
              <chakra.p>{headerLink.label}</chakra.p>
            </HStack>
          </Link>
        ))}
      </>
    )
  }
  const SubLinks = () => {
    return (
      <Flex columnGap={{ base: 2, lg: 10 }} wrap="wrap">
        {HeaderSubLinks.map((link) => (
          <NextLink key={link.key} href={link.href} passHref>
            <Link whiteSpace={"nowrap"} textStyle={"body-medium-12pt"}>
              {link.label}
            </Link>
          </NextLink>
        ))}
      </Flex>
    )
  }
  const DevelopersLink = () => (
    <chakra.a
      display="inline-block"
      href="https://developers.monicle.co.jp/"
      target="_blank"
      rel="noopener"
      bg="black"
      w={{ base: "full", lg: "170px" }}
      py={{ base: 4, lg: 2 }}
      border="1px solid #FFFFFF"
    >
      <VStack spacing={1}>
        <chakra.img
          src="/images/monicle-logo-white.svg"
          w={{ base: "120px", lg: "64px" }}
        />
        <chakra.p
          color="white"
          fontFamily="Montserrat"
          fontWeight={600}
          fontSize={{ base: "20px", lg: "10px" }}
          lineHeight={{ base: "24px", lg: "13px" }}
          letterSpacing="0.05em"
        >
          developers
        </chakra.p>
      </VStack>
    </chakra.a>
  )
  // Chakra UI の Collapse が useLayoutEffect を使っていて warning が出る問題
  // に対応している。以下メッセージ冒頭。
  // Warning: useLayoutEffect does nothing on the server,
  // because its effect cannot be encoded into the server renderer's output format.
  // 対応方法は
  // https://reactjs.org/link/uselayouteffect-ssr
  // を参考にした。
  const [showHeader, setShowHeader] = useState(false)
  useEffect(() => {
    setShowHeader(true)
  }, [])
  if (!showHeader) {
    return null
  }

  return (
    <chakra.header
      bgColor="white"
      textColor="#303030"
      position="absolute"
      w="full"
      style={{ zIndex: 10 }}
      ref={headerRef}
    >
      {isMobile ? (
        <Container maxW="container.xl" px={4}>
          <Collapse in={isOpen} startingHeight={HEADER_HEIGHT_SP}>
            <Box>
              <Flex alignItems="center" h={HEADER_HEIGHT_SP}>
                <Flex alignItems="baseline">
                  <Logo />
                </Flex>
                <Spacer />
                <Menu />
              </Flex>
              <VStack mt={14} mb={4} spacing={8}>
                <VStack spacing={8} alignItems="flex-start">
                  <Links />
                </VStack>
                <VStack spacing={4}>
                  <EngineerLpLink w="320px" h="46px" mr={0} />
                  <Entry w="320px" h="46px" />
                </VStack>
                <VStack spacing={6}>
                  <chakra.a
                    href="https://media.monicle.co.jp/?utm_source=monicle&utm_medium=referral&utm_campaign=hamburger-menu"
                    target="_blank"
                    rel="noopener"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"320px"}
                    h={"105px"}
                    border={"solid 1px #000"}
                  >
                    <NextImage
                      src="/images/media/logo.svg"
                      width={280}
                      height={38}
                    />
                  </chakra.a>
                  <DevelopersLink />
                </VStack>
                <SubLinks />
              </VStack>
            </Box>
          </Collapse>
        </Container>
      ) : (
        <Container maxW="container.xl" pl={"36px"} px={"48px"}>
          <Collapse in={isOpen} startingHeight={HEADER_HEIGHT}>
            <Flex direction="column">
              <Flex direction="row" alignItems="center" h={HEADER_HEIGHT}>
                <Flex alignItems="baseline">
                  <Logo />
                </Flex>
                <Spacer />
                <EngineerLpLink arrow />
                <Entry arrow />
                <Menu />
              </Flex>
              <Spacer />
              <Flex justifyContent="space-between" mt={"87px"}>
                <SimpleGrid columns={2} spacingX={12} spacingY={14} mx={"32px"}>
                  <Links />
                </SimpleGrid>
                <Box flex={0} mx={12}>
                  <chakra.a
                    href="https://media.monicle.co.jp/?utm_source=monicle&utm_medium=referral&utm_campaign=global-navigation"
                    target="_blank"
                    rel="noopener"
                    w="398px"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <NextImage
                      src="/images/media/logo.svg"
                      width={365}
                      height={49}
                    />
                  </chakra.a>
                </Box>
              </Flex>
              <Spacer />
              <Flex
                justifyContent="flex-end"
                px={14}
                pb={6}
                gap={10}
                mt={"112px"}
                alignItems="center"
              >
                <SubLinks />
                <DevelopersLink />
              </Flex>
            </Flex>
          </Collapse>
        </Container>
      )}
    </chakra.header>
  )
}

const HeaderLinks = [
  { label: "home", to: "home" },
  { label: "about us", to: "aboutus" },
  { label: "member", to: "member" },
  { label: "meeting", to: "meeting" },
  { label: "jobs", to: "jobs" },
  { label: "benefit", to: "benefit" },
]

const HeaderSubLinks: {
  key: string
  href: LinkProps["href"]
  label: string
}[] = [
  { key: "privacy", href: `${CORPORATE_BASE_URL}/privacy`, label: "個人情報保護方針" },
  { key: "security", href: `${CORPORATE_BASE_URL}/security`, label: "情報セキュリティ方針" },
  { key: "terms-of-use", href: `${CORPORATE_BASE_URL}/terms-of-use`, label: "ご利用にあたって" },
  { key: "contact", href: `${CORPORATE_BASE_URL}/contact`, label: "お問い合わせ" },
]

const HEADER_HEIGHT_SP = "70px"
const HEADER_HEIGHT = "100px"
export const HeaderSpacer = () => (
  <chakra.div h={{ base: HEADER_HEIGHT_SP, lg: HEADER_HEIGHT }} />
)

export default RecruitHeader
