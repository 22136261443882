import { BoxProps, chakra } from "@chakra-ui/react"
import React from "react"

/** <Wbr>自動で</Wbr>
 * <Wbr>改行される場合、</Wbr>
 * <Wbr>このコンポーネントで囲った</Wbr>
 * 要素はで
 * きるだ
 * け改行さ
 * れなくな
 * ります。 */
export const Wbr: React.FC<
  BoxProps & {
    /** このコンポーネントの中でもさらに改行したくない場合 */
    nowrap?: boolean
  }
> = ({ nowrap, ...props }) => (
  <chakra.span
    d="inline-block"
    whiteSpace={nowrap ? "nowrap" : undefined}
    {...props}
  />
)
